/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

ion-toolbar {
  background-color: black;
}

ion-button {
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.login-screen {
  max-width: 600px;
  margin: 10px auto;
  padding: 0px 15px;
  ion-button {
    width: 100%;
  }
  .sign-up-link {
    margin-top: 5px;
    text-align: center;
    a {
      color: #444444;
      text-decoration: none;
    }
  }
}
.invalid-feedback {
  font-size: 12px;
  color: #eb445a;
  margin-bottom: 3px;
}

.split-pane-visible > .split-pane-side {
  max-width: 20% !important;
}

ion-content {
  @media (max-width: 1024px) {
    --padding-bottom: 15px;
  }
}

ion-content {
  &.no-padding {
    --padding-bottom: 0px;
  }
}

.password-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 15px;
  .form-container {
    width: 100%;
    max-width: 480px;
    h2 {
      font-size: 23px;
      font-weight: bold;
      color: #000000;
      margin: 5px 0px 20px;
    }
  }
  @media (max-width: 767px) {
    height: auto;
    padding: 15px;
  }
}

@media (max-width: 1024px) {
  ion-menu-button {
    display: none !important;
  }
}
// @media (max-width: 539px) {
//   ion-content {
//     --padding-bottom: 100px;
//   }
//   ion-content {
//     &.no-padding {
//       --padding-bottom: 0px;
//     }
//   }
// }

.menu-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  margin-right: 5px;
  cursor: pointer;
}

.responsive-data-table {
  table {
    @media (max-width: 767px) {
      display: block;
      border: 0px;
      box-shadow: none;
      thead {
        display: none !important;
      }
      tbody {
        display: block;
        tr {
          display: block;
          height: auto !important;
          padding: 20px;
          padding-top: 27px;

          margin: 0px;
          box-shadow: none;
          border-bottom: 1px solid #cccccc;
          border-radius: 0px;
          position: relative;
          td {
            display: block;
            width: 100% !important;
            border: 0px;
            text-align: left !important;
            padding-left: 115px;
            position: relative;
            margin-bottom: 8px;
            &:before {
              content: "";
              position: absolute;
              top: 0px;
              left: 0px;
              display: block;
              font-weight: bold;
            }
            &.cdk-column-fromHandle,
            &.cdk-column-toHandle {
              &:before {
                content: "User Handle:";
              }
            }
            &.cdk-column-message {
              &:before {
                content: "Message:";
              }
            }
            &.cdk-column-messageTs {
              &:before {
                content: "Message Time:";
              }
            }
            &.cdk-column-actions {
              &:before {
                content: "Action:";
              }
            }
            &.cdk-column-duckerHandle {
              &:before {
                content: "Ducker Handle:";
              }
            }
            &.cdk-column-duckingTs {
              &:before {
                content: "Ducking Ts:";
              }
            }
            &.cdk-column-cityState {
              &:before {
                content: "City State:";
              }
            }
            &.cdk-column-duckingType {
              &:before {
                content: "Ducking Type:";
              }
            }
          }
        }
      }
    }
  }
}

.desktopView {
  display: block;
  @media (max-width: 767px) {
    display: none !important;
  }
}

.mobileTableView {
  display: none;
  @media (max-width: 767px) {
    display: block;
    ion-button {
      width: 100%;
    }
  }
}

.form-control {
  &:focus {
    box-shadow: none !important;
  }
}

ion-icon {
  &.support-icon {
    font-size: 35px;
    margin-right: 7px;
    cursor: pointer;
  }
  &.setting-icon {
    font-size: 33px;
    margin-right: 7px;
    cursor: pointer;
  }
}
