/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
ion-toolbar {
  background-color: black;
}

ion-button {
  text-transform: uppercase;
}
@media (max-width: 768px) {
  ion-button {
    font-size: 14px;
  }
}

.login-screen {
  max-width: 600px;
  margin: 10px auto;
  padding: 0px 15px;
}
.login-screen ion-button {
  width: 100%;
}
.login-screen .sign-up-link {
  margin-top: 5px;
  text-align: center;
}
.login-screen .sign-up-link a {
  color: #444444;
  text-decoration: none;
}

.invalid-feedback {
  font-size: 12px;
  color: #eb445a;
  margin-bottom: 3px;
}

.split-pane-visible > .split-pane-side {
  max-width: 20% !important;
}

@media (max-width: 1024px) {
  ion-content {
    --padding-bottom: 15px;
  }
}

ion-content.no-padding {
  --padding-bottom: 0px;
}

.password-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 15px;
}
.password-reset .form-container {
  width: 100%;
  max-width: 480px;
}
.password-reset .form-container h2 {
  font-size: 23px;
  font-weight: bold;
  color: #000000;
  margin: 5px 0px 20px;
}
@media (max-width: 767px) {
  .password-reset {
    height: auto;
    padding: 15px;
  }
}

@media (max-width: 1024px) {
  ion-menu-button {
    display: none !important;
  }
}
.menu-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  margin-right: 5px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .responsive-data-table table {
    display: block;
    border: 0px;
    box-shadow: none;
  }
  .responsive-data-table table thead {
    display: none !important;
  }
  .responsive-data-table table tbody {
    display: block;
  }
  .responsive-data-table table tbody tr {
    display: block;
    height: auto !important;
    padding: 20px;
    padding-top: 27px;
    margin: 0px;
    box-shadow: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0px;
    position: relative;
  }
  .responsive-data-table table tbody tr td {
    display: block;
    width: 100% !important;
    border: 0px;
    text-align: left !important;
    padding-left: 115px;
    position: relative;
    margin-bottom: 8px;
  }
  .responsive-data-table table tbody tr td:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    font-weight: bold;
  }
  .responsive-data-table table tbody tr td.cdk-column-fromHandle:before, .responsive-data-table table tbody tr td.cdk-column-toHandle:before {
    content: "User Handle:";
  }
  .responsive-data-table table tbody tr td.cdk-column-message:before {
    content: "Message:";
  }
  .responsive-data-table table tbody tr td.cdk-column-messageTs:before {
    content: "Message Time:";
  }
  .responsive-data-table table tbody tr td.cdk-column-actions:before {
    content: "Action:";
  }
  .responsive-data-table table tbody tr td.cdk-column-duckerHandle:before {
    content: "Ducker Handle:";
  }
  .responsive-data-table table tbody tr td.cdk-column-duckingTs:before {
    content: "Ducking Ts:";
  }
  .responsive-data-table table tbody tr td.cdk-column-cityState:before {
    content: "City State:";
  }
  .responsive-data-table table tbody tr td.cdk-column-duckingType:before {
    content: "Ducking Type:";
  }
}

.desktopView {
  display: block;
}
@media (max-width: 767px) {
  .desktopView {
    display: none !important;
  }
}

.mobileTableView {
  display: none;
}
@media (max-width: 767px) {
  .mobileTableView {
    display: block;
  }
  .mobileTableView ion-button {
    width: 100%;
  }
}

.form-control:focus {
  box-shadow: none !important;
}

ion-icon.support-icon {
  font-size: 35px;
  margin-right: 7px;
  cursor: pointer;
}
ion-icon.setting-icon {
  font-size: 33px;
  margin-right: 7px;
  cursor: pointer;
}